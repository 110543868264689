import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa';
import { DonationDetail } from '../types';

interface DonationDetailsModalProps {
  donor: {
    id: number;
    name: string;
    totalDonationsUSD: number;
    donationsCount: number;
  };
  onClose: () => void;
}

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: white;
  width: 80%;
  max-width: 700px;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  max-height: 80vh;
  overflow-y: auto;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  th, td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
  }
`;

const DonationDetailsModal: React.FC<DonationDetailsModalProps> = ({ donor, onClose }) => {
  const [donations, setDonations] = useState<DonationDetail[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchDonationDetails = async () => {
      setLoading(true);
      try {
        // Replace with your backend API endpoint
        const response = await axios.get(`/api/donations/${donor.id}`);
        setDonations(response.data);
      } catch (err) {
        setError('Failed to fetch donation details.');
      } finally {
        setLoading(false);
      }
    };

    fetchDonationDetails();
  }, [donor.id]);

  return (
    <ModalOverlay>
      <ModalContent>
        <CloseButton onClick={onClose}><FaTimes /></CloseButton>
        <h2>{donor.name}'s Donations</h2>
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {!loading && !error && donations.length === 0 && <p>No donations yet.</p>}
        {!loading && !error && donations.length > 0 && (
          <Table>
            <thead>
              <tr>
                <th>Date-Time</th>
                <th>Amount</th>
                <th>Currency</th>
              </tr>
            </thead>
            <tbody>
              {donations.map((donation) => (
                <tr key={donation.id}>
                  <td>{new Date(donation.dateTime).toLocaleString()}</td>
                  <td>{donation.amount}</td>
                  <td>{donation.currency}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {/* Optional: Add Export Button */}
      </ModalContent>
    </ModalOverlay>
  );
};

export default DonationDetailsModal;
