import React from 'react';
import styled from 'styled-components';

interface FilterOptionsProps {
  selectedCurrency: string;
  onCurrencyChange: (currency: string) => void;
}

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Dropdown = styled.select`
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const FilterOptions: React.FC<FilterOptionsProps> = ({ selectedCurrency, onCurrencyChange }) => {
  return (
    <FilterContainer>
      <div>
        <label htmlFor="currency-filter">Filter by Currency: </label>
        <Dropdown
          id="currency-filter"
          value={selectedCurrency}
          onChange={(e) => onCurrencyChange(e.target.value)}
        >
          <option value="USD">USD</option>
          <option value="BTC">Bitcoin</option>
          <option value="ETH">Ethereum</option>
          <option value="KOFI">Ko-fi</option>
          <option value="PATREON">Patreon</option>
          {/* Add more currencies as needed */}
        </Dropdown>
      </div>
      {/* Future: Add more filters or sorting options */}
    </FilterContainer>
  );
};

export default FilterOptions;
