import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const Container = styled.div`
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Input = styled.input<{ hasError?: boolean }>`
  padding: 10px;
  border: ${(props) => (props.hasError ? '2px solid red' : '1px solid #ccc')};
  border-radius: 4px;
`;

const TextArea = styled.textarea<{ hasError?: boolean }>`
  padding: 10px;
  border: ${(props) => (props.hasError ? '2px solid red' : '1px solid #ccc')};
  border-radius: 4px;
  resize: vertical;
`;

const SubmitButton = styled.button`
  background-color: #004aad;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #002f6c;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 0.9em;
`;

const SuccessMessage = styled.p`
  color: green;
  font-size: 0.9em;
`;

const ContactPage: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState<string>('');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [success, setSuccess] = useState('');

  const validate = (): boolean => {
    const newErrors: { [key: string]: string } = {};
    if (!name.trim()) newErrors.name = 'Name is required.';
    if (!email.trim()) {
      newErrors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Email is invalid.';
    }
    if (!message.trim()) newErrors.message = 'Message is required.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSuccess('');
    if (!validate()) return;

    try {
      // Replace with your backend API endpoint
      const response = await axios.post('/api/contact', {
        name,
        email,
        message,
      });
      if (response.status === 200) {
        setSuccess('Your message has been sent successfully!');
        setName('');
        setEmail('');
        setMessage('');
      }
    } catch (error) {
      console.error(error);
      setErrors({ submit: 'An error occurred. Please try again later.' });
    }
  };

  return (
    <Container>
      <h2>Contact Us</h2>
      <Form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name</label>
          <Input
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            hasError={!!errors.name}
          />
          {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
        </div>

        <div>
          <label htmlFor="email">Email</label>
          <Input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            hasError={!!errors.email}
          />
          {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
        </div>

        <div>
          <label htmlFor="message">Message</label>
          <TextArea
            id="message"
            rows={5}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            hasError={!!errors.message}
          />
          {errors.message && <ErrorMessage>{errors.message}</ErrorMessage>}
        </div>

        {errors.submit && <ErrorMessage>{errors.submit}</ErrorMessage>}
        {success && <SuccessMessage>{success}</SuccessMessage>}

        <SubmitButton type="submit">Send Message</SubmitButton>
      </Form>
    </Container>
  );
};

export default ContactPage;
