import React from 'react';
import styled from 'styled-components';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';

const FooterContainer = styled.footer`
  background-color: #f1f1f1;
  padding: 20px;
  text-align: center;
`;

const Links = styled.div`
  margin-bottom: 10px;

  a {
    margin: 0 10px;
    color: #004aad;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const SocialIcons = styled.div`
  a {
    margin: 0 5px;
    color: #004aad;
    font-size: 1.2em;

    &:hover {
      color: #002f6c;
    }
  }
`;

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <Links>
        <a href="/privacy-policy">Privacy Policy</a>
        <a href="/terms-of-service">Terms of Service</a>
        <a href="/support">Support</a>
      </Links>
      <SocialIcons>
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
      </SocialIcons>
      <p>© {new Date().getFullYear()} Who Paid The Most. All rights reserved.</p>
    </FooterContainer>
  );
};

export default Footer;
