import React from 'react';
import styled from 'styled-components';
import DonationList from '../components/DonationsList';
import FilterOptions from '../components/FilterOptions';

const Container = styled.div`
  padding: 20px;
`;

const TopDonorsPage: React.FC = () => {
  const [currencyFilter, setCurrencyFilter] = React.useState('USD');

  return (
    <Container>
      <h2>Top Donors</h2>
      <FilterOptions selectedCurrency={currencyFilter} onCurrencyChange={setCurrencyFilter} />
      <DonationList />
    </Container>
  );
};

export default TopDonorsPage;
