import React from 'react';
import styled from 'styled-components';
import SplashImage from '../assets/images/splash.png'; 


const Container = styled.div`
  display: flex;
  with: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgb(51, 51, 68);
  padding: 50px;
`;

const SplashPage: React.FC = () => {

  // Implement search and filter logic here or pass as props to DonationsList

  return (
    <Container>
      <img src={SplashImage} alt="Sample" />
    </Container>
  );
};

export default SplashPage;
