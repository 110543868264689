import React from 'react';
import styled from 'styled-components';
import { FaBitcoin, FaEthereum, FaPaypal, FaStripe } from 'react-icons/fa';
import { SiKofi, SiPatreon } from 'react-icons/si';

interface PaymentMethodIconsProps {
  selected: string;
  onSelect: (method: string) => void;
}

const IconsContainer = styled.div`
  display: flex;
  gap: 15px;
`;

const IconWrapper = styled.div<{ selected: boolean }>`
  font-size: 2em;
  color: ${(props) => (props.selected ? '#004aad' : '#555')};
  cursor: pointer;

  &:hover {
    color: #004aad;
  }
`;

const PaymentMethodIcons: React.FC<PaymentMethodIconsProps> = ({ selected, onSelect }) => {
  const paymentMethods = [
    { name: 'Bitcoin', icon: <FaBitcoin /> },
    { name: 'Ethereum', icon: <FaEthereum /> },
    { name: 'Ko-fi', icon: <SiKofi /> },
    { name: 'Patreon', icon: <SiPatreon /> },
    { name: 'PayPal', icon: <FaPaypal /> },
    { name: 'Stripe', icon: <FaStripe /> },
    // Add more payment methods as needed
  ];

  return (
    <IconsContainer>
      {paymentMethods.map((method) => (
        <IconWrapper
          key={method.name}
          selected={selected === method.name.toUpperCase()}
          onClick={() => onSelect(method.name.toUpperCase())}
          title={method.name}
        >
          {method.icon}
        </IconWrapper>
      ))}
    </IconsContainer>
  );
};

export default PaymentMethodIcons;
