import React, { useState } from 'react';
import styled from 'styled-components';
import PaymentMethodIcons from './PaymentMethodIcons';
import axios from 'axios';

const FormContainer = styled.form`
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 8px;
  max-width: 600px;
  margin: 0 auto;
`;

const InputGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

const Input = styled.input<{ hasError?: boolean }>`
  width: 100%;
  padding: 10px;
  border: ${(props) => (props.hasError ? '2px solid red' : '1px solid #ccc')};
  border-radius: 4px;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const DonateButton = styled.button`
  background-color: #004aad;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;

  &:hover {
    background-color: #002f6c;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 0.9em;
`;

const SuccessMessage = styled.p`
  color: green;
  font-size: 0.9em;
`;

const DonationForm: React.FC = () => {
  const [name, setName] = useState('');
  const [amount, setAmount] = useState<number | ''>('');
  const [currency, setCurrency] = useState('USD');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [success, setSuccess] = useState('');

  const validate = (): boolean => {
    const newErrors: { [key: string]: string } = {};
    if (!name.trim()) newErrors.name = 'Name is required.';
    if (amount === '' || amount <= 0) newErrors.amount = 'Enter a valid amount.';
    if (!paymentMethod) newErrors.paymentMethod = 'Select a payment method.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSuccess('');
    if (!validate()) return;

    try {
      // Replace with your backend API endpoint
      const response = await axios.post('/api/donations', {
        name,
        amount,
        currency,
        paymentMethod,
      });
      if (response.status === 200) {
        setSuccess('Donation successful! Thank you for your contribution.');
        setName('');
        setAmount('');
        setPaymentMethod('');
      }
    } catch (error) {
      console.error(error);
      setErrors({ submit: 'An error occurred. Please try again later.' });
    }
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <InputGroup>
        <Label htmlFor="name">Name</Label>
        <Input
          id="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          hasError={!!errors.name}
        />
        {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
      </InputGroup>

      <InputGroup>
        <Label htmlFor="amount">Amount</Label>
        <Input
          id="amount"
          type="number"
          value={amount}
          onChange={(e) => setAmount(Number(e.target.value))}
          hasError={!!errors.amount}
        />
        {errors.amount && <ErrorMessage>{errors.amount}</ErrorMessage>}
      </InputGroup>

      <InputGroup>
        <Label htmlFor="currency">Currency</Label>
        <Select
          id="currency"
          value={currency}
          onChange={(e) => setCurrency(e.target.value)}
        >
          <option value="USD">USD</option>
          <option value="BTC">Bitcoin</option>
          <option value="ETH">Ethereum</option>
          <option value="KOFI">Ko-fi</option>
          <option value="PATREON">Patreon</option>
          {/* Add more currencies as needed */}
        </Select>
      </InputGroup>

      <InputGroup>
        <Label>Payment Method</Label>
        <PaymentMethodIcons
          selected={paymentMethod}
          onSelect={(method) => setPaymentMethod(method)}
        />
        {errors.paymentMethod && <ErrorMessage>{errors.paymentMethod}</ErrorMessage>}
      </InputGroup>

      {errors.submit && <ErrorMessage>{errors.submit}</ErrorMessage>}
      {success && <SuccessMessage>{success}</SuccessMessage>}

      <DonateButton type="submit">Donate</DonateButton>
    </FormContainer>
  );
};

export default DonationForm;
