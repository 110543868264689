import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import DonationDetailsModal from './DonationDetailsModal';

interface DonationSummary {
  id: number;
  name: string;
  totalDonationsUSD: number;
  donationsCount: number;
}

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  th, td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }

  th {
    cursor: pointer;
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #f1f1f1;
  }
`;

const DonationsList: React.FC = () => {
  const [donations, setDonations] = useState<DonationSummary[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [selectedDonor, setSelectedDonor] = useState<DonationSummary | null>(null);

  useEffect(() => {
    const fetchDonations = async () => {
      setLoading(true);
      try {
        // Replace with your backend API endpoint
        const response = await axios.get('/api/donations');
        setDonations(response.data);
      } catch (err) {
        setError('Failed to fetch donations.');
      } finally {
        setLoading(false);
      }
    };

    fetchDonations();
  }, []);

  const handleRowClick = (donor: DonationSummary) => {
    setSelectedDonor(donor);
  };

  const closeModal = () => {
    setSelectedDonor(null);
  };

  if (loading) return <p>Loading donations...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Total Donations (USD)</th>
            <th>Number of Donations</th>
          </tr>
        </thead>
        <tbody>
          {donations.map((donor) => (
            <tr key={donor.id} onClick={() => handleRowClick(donor)}>
              <td>{donor.name}</td>
              <td>${donor.totalDonationsUSD.toLocaleString()}</td>
              <td>{donor.donationsCount}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {selectedDonor && (
        <DonationDetailsModal donor={selectedDonor} onClose={closeModal} />
      )}
    </>
  );
};

export default DonationsList;
