import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
`;

const AboutPage: React.FC = () => {
  return (
    <Container>
      <h2>About "Who Paid The Most"</h2>
      <p>
        "Who Paid The Most" is a unique platform that allows users to donate money under specific names.
        Track and see who tops the list based on total contributions. It's a fun and engaging way to support
        causes, individuals, or projects you care about.
      </p>
      <p>
        Our mission is to foster a transparent and competitive environment where generosity is celebrated.
        Join us in making a difference today!
      </p>
    </Container>
  );
};

export default AboutPage;
