import React from 'react';
import styled from 'styled-components';

interface SearchBarProps {
  searchTerm: string;
  onSearchChange: (term: string) => void;
}

const SearchInput = styled.input`
  width: 100%;
  max-width: 400px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const SearchBar: React.FC<SearchBarProps> = ({ searchTerm, onSearchChange }) => {
  return (
    <div>
      <SearchInput
        type="text"
        placeholder="Search for a name..."
        value={searchTerm}
        onChange={(e) => onSearchChange(e.target.value)}
      />
    </div>
  );
};

export default SearchBar;
