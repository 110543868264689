import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import TopDonorsPage from './pages/TopDonorsPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import GlobalStyles from './styles/GlobalStyles';
import SplashPage from './pages/SplashPage';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrapper = styled.main`
  flex: 1;
`;

const App: React.FC = () => {
  return (
    <Router>
      <AppContainer>
        <GlobalStyles />
        {/* <Header /> */}
        <ContentWrapper>
          <Routes>
            <Route path="/" element={<SplashPage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/top-donors" element={<TopDonorsPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            {/* Future: Add more routes as needed */}
          </Routes>
        </ContentWrapper>
        <Footer />
      </AppContainer>
    </Router>
  );
};

export default App;
